import { defineComponent, computed, PropType, ref } from 'vue';
import styles from './index.css?module';
import {
  Button,
  BtnVariant,
  BtnSize,
  Icon,
  Text,
  TextColor,
  TextSize,
  DESIRE_ICONS,
} from '@/components/ui';
import { IAdInfo, IPhotoMeta, IProfile } from '@/api/ad';
import { useSemaphore, useS3 } from '@/composables';
import { ToastType, useAdStore, useCupidStore, useToast } from '@/store';
import { useTelegramWebApp } from '@/plugins';
import { useI18n } from 'vue-i18n';
import throttle from 'lodash.throttle';

interface HeartIcon {
  icon: string;
  color: string;
}

export interface LazyPhoto {
  url: string;
  meta: IPhotoMeta;
  inLoad: boolean;
  isLoaded: boolean;
}

export default defineComponent({
  props: {
    item: {
      type: Object as PropType<Readonly<IAdInfo>>,
      required: true,
    },
    showDislikeBtn: {
      type: Boolean,
      default: false,
    },
    onOpenGallery: {
      type: Function as PropType<(photos: LazyPhoto[], index: number) => void>,
    },
  },

  setup(props, { emit }) {
    const { t } = useI18n();
    const adStore = useAdStore();
    const toastStore = useToast();
    const cupidStore = useCupidStore();
    const semaphore = useSemaphore();
    const tgWebApp = useTelegramWebApp();
    const media = useS3();

    const swiperEl = ref(null);
    const wasDislike = ref(false);

    const profile = computed((): IProfile => {
      return props.item.profile;
    });

    const profileInfo = computed((): string => {
      const result = [] as string[];

      if (profile.value.age > 0) {
        const ageText = t('component.adCard.ageMeasure', profile.value.age);
        result.push(`${profile.value.age} ${ageText}`);
      }

      if (profile.value.height > 0) {
        const heightMeasure = t('component.adCard.heightMeasure');
        result.push(`${profile.value.height} ${heightMeasure}`);
      }

      return result.join(', ');
    });

    const onlineStatus = computed((): string => {
      if (profile.value.online && profile.value.online?.status?.length > 0) {
        return profile.value.online?.status;
      }
      return '';
    });

    const cityName = computed((): string => {
      const cityPrefix = t('component.adCard.cityPrefix');
      return `${cityPrefix} ${props.item.city.name}`;
    });

    const distanceName = computed((): string => {
      return props.item.distance?.value;
    });

    const chatLink = computed((): string => {
      return props.item.chat?.tgChatLink || '';
    });

    const showDislike = computed((): boolean => {
      return props.showDislikeBtn && !props.item.hasLike && !wasDislike.value;
    });

    const showLike = computed((): boolean => {
      return !wasDislike.value;
    });

    const showChatLink = computed((): boolean => {
      return chatLink.value?.length > 0 && !wasDislike.value;
    });

    const photos = ref(
      props.item.photos.map((p, index) => {
        return {
          url: media.resize(p.path, { w: 500, h: 500 }),
          meta: p.meta,
          inLoad: index == 0,
        } as LazyPhoto;
      })
    );

    const HeartIcon = computed((): HeartIcon => {
      if (props.item.hasLike) {
        return { icon: 'heart-solid', color: 'text-textHighlight-danger' };
      }
      return { icon: 'heart', color: '' };
    });

    const desireIcon = computed((): string => {
      return DESIRE_ICONS[props.item.profile.desire.slug] ?? '';
    });

    const onLike = async () => {
      if (props.item.hasLike || !semaphore.tryAcquire()) {
        return;
      }

      const resp = await cupidStore.like(props.item.profile.userId);
      if (resp.ok) {
        adStore.setAdLike(props.item.id);
      } else {
        toastStore.add(ToastType.ERROR, resp.message);
      }

      semaphore.release();
    };

    const onDislike = async () => {
      if (!semaphore.tryAcquire()) {
        return;
      }

      const resp = await cupidStore.dislike(props.item.profile.userId);
      if (resp.ok) {
        wasDislike.value = true;
      } else {
        toastStore.add(ToastType.ERROR, resp.message);
      }

      semaphore.release();
    };

    const onOpenChat = () => {
      if (chatLink.value.length > 0) {
        tgWebApp.openTelegramLink(chatLink.value);
      }
    };

    const onImageLoaded = (photo: LazyPhoto) => {
      photo.inLoad = false;
      photo.isLoaded = true;
    };

    const onScroll = throttle((e: Event) => {
      const target = e.target as HTMLElement;

      for (let i = 1; i < target.children.length; i++) {
        const photo = photos.value[i - 1];
        if (!photo || photo.isLoaded || photo.inLoad) {
          continue;
        }

        const child = target.children[i] as HTMLElement;
        if (isInViewport(child)) {
          photo.inLoad = true;
        }
        break;
      }
    }, 25);

    function isInViewport(element: HTMLElement): boolean {
      const rect = element.getBoundingClientRect();
      const windowWidth =
        window.innerWidth || document.documentElement.clientWidth;
      const inViewWidth = windowWidth + rect.width;

      return rect.top >= 0 && rect.left >= 0 && rect.right <= inViewWidth;
    }

    const openPhoto = (index: number) => {
      emit('openGallery', photos.value, index);
    };

    return () => (
      <div class={styles.Container}>
        <div class={styles.Swiper} ref={swiperEl} onScroll={onScroll}>
          <div class={'flex flex-col v-full justify-center'}>
            <div class={[styles.AdCard]}>
              <div class={styles.AdCardTextWrapper}>
                <Text size={TextSize.H4}>{profile.value.name}</Text>
                <div class={styles.AdCardHeader}>
                  <Text color={TextColor.SECONDARY}>{profileInfo.value}</Text>
                  {onlineStatus.value.length > 0 && (
                    <Text color={TextColor.SECONDARY}>
                      , {onlineStatus.value}
                    </Text>
                  )}
                </div>

                <div class={styles.AdTextContainer}>
                  {props.item.profile.desire && (
                    <span class={'flex flex-row items-center'}>
                      <img
                        class={'w-5 h-5 mr-2'}
                        src={require(`@/assets/images/${desireIcon.value}.png`)}
                        alt=""
                      />
                      <Text
                        class={'uppercase'}
                        size={TextSize.BASE}
                        color={TextColor.SECONDARY_DARK}
                      >
                        {props.item.profile.desire.name}
                      </Text>
                    </span>
                  )}
                  {props.item.description}
                </div>

                <div class={'flex flex-row'}>
                  <Text
                    class={'truncate max-w-[14rem]'}
                    color={TextColor.SECONDARY}
                  >
                    {cityName.value}
                  </Text>
                  {distanceName.value?.length > 0 && (
                    <Text color={TextColor.SECONDARY}>
                      , {distanceName.value}
                    </Text>
                  )}
                </div>
              </div>
            </div>
          </div>

          {photos.value.map((photo, index) => {
            return (
              <div
                key={`photo-${index}`}
                class={[styles.AdCardPhoto]}
                onClick={() => openPhoto(index)}
              >
                {!(photo.isLoaded || photo.inLoad) && (
                  <div
                    class={[
                      styles.AdCardPhotoImg,
                      styles.AdCardPhotoImgNotLoad,
                    ]}
                  />
                )}
                {(photo.isLoaded || photo.inLoad) && (
                  <img
                    class={[
                      styles.AdCardPhotoImg,
                      photo.inLoad && styles.AdCardPhotoImgNotLoad,
                    ]}
                    src={photo.url}
                    onLoad={() => onImageLoaded(photo)}
                    alt={''}
                  />
                )}
              </div>
            );
          })}
        </div>

        <div class={styles.LikeIconContainer}>
          {showChatLink.value && (
            <div class={styles.LikeIconWrapper}>
              <Button
                variant={BtnVariant.TRANSPARENCY}
                size={BtnSize.INHERIT}
                onClick={onOpenChat}
              >
                <Icon
                  class={['text-xl', 'text-bgSecondary-light']}
                  name={'chat-bubble-oval-left'}
                />
              </Button>
            </div>
          )}
          {showDislike.value && (
            <div class={styles.LikeIconWrapper}>
              <Button
                variant={BtnVariant.TRANSPARENCY}
                size={BtnSize.INHERIT}
                onClick={onDislike}
              >
                <Icon
                  class={['text-2xl', 'text-textHighlight-danger']}
                  name={'no-symbol'}
                />
              </Button>
            </div>
          )}
          {showLike.value && (
            <div class={styles.LikeIconWrapper}>
              <Button
                variant={BtnVariant.TRANSPARENCY}
                size={BtnSize.INHERIT}
                onClick={onLike}
              >
                <Icon
                  class={['text-2xl', HeartIcon.value.color]}
                  name={HeartIcon.value.icon}
                />
              </Button>
            </div>
          )}
        </div>
      </div>
    );
  },
});

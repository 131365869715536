import { useRoute } from 'vue-router';

export function useRouteBuilder() {
  const route = useRoute();

  const settings = () => {
    return {
      name: 'settings',
      query: route?.query,
    };
  };

  const settingAccount = () => {
    return {
      name: 'settings.account',
      query: route?.query,
    };
  };

  const userDetail = () => {
    return {
      name: 'user',
      query: route?.query,
    };
  };

  const editProfile = () => {
    return {
      name: 'user.profile',
      query: route?.query,
    };
  };

  const deleteAccount = () => {
    return {
      name: 'system.delete',
      query: route?.query,
    };
  };

  const adsList = (
    cityId?: string,
    distance?: string,
    desire?: string,
    ageFrom?: number,
    ageTo?: number,
    heightFrom?: number,
    heightTo?: number,
    hasPhoto?: string,
    isOnline?: string,
    newest?: string,
    withMatch?: string
  ) => {
    return {
      name: 'ads',
      query: {
        cityId,
        distance,
        desire,
        ageFrom,
        ageTo,
        heightFrom,
        heightTo,
        hasPhoto,
        isOnline,
        newest,
        withMatch,
      },
    };
  };

  return {
    adsList,
    userDetail,
    editProfile,
    settings,
    settingAccount,
    deleteAccount,
  };
}

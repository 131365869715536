import { api as client, Response } from '@/api';
import {
  IDislikeRequest,
  IDislikeResponse,
  ILikeRequest,
  ILikeResponse,
} from '@/api/cupid/types';

export default {
  like: async (payload: ILikeRequest): Promise<Response<ILikeResponse>> => {
    return await client.post<ILikeResponse, ILikeRequest>(
      `/api/cupid/${payload.userId}`,
      payload
    );
  },

  dislike: async (
    payload: IDislikeRequest
  ): Promise<Response<IDislikeResponse>> => {
    return await client.post<IDislikeResponse, IDislikeRequest>(
      `/api/cupid/${payload.userId}/dislike`,
      payload
    );
  },
};

import { defineStore } from 'pinia';
import { Response } from '@/api';
import { api, IDislikeResponse, ILikeResponse } from '@/api/cupid';

export const useCupidStore = defineStore('cupid', () => {
  const like = async (userId: string): Promise<Response<ILikeResponse>> => {
    return await api.like({ userId });
  };

  const dislike = async (
    userId: string
  ): Promise<Response<IDislikeResponse>> => {
    return await api.dislike({ userId });
  };

  return {
    like,
    dislike,
  };
});
